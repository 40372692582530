.rty {
    .change-password {
        display: flex;
        font-family: var(--font-family);
        height: 100vh;
        position: relative;

        &.hide {
            display: none;
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            opacity: .3;
            background-image: url(../../assets/abstract.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;

            .form-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            
                width: 310px;
                height: 100%;
                text-align: center;
                border-radius: 5px;
                background: #fff;
                position: relative;
                overflow: hidden;
                box-shadow: 0 6px 31px -2px rgb(0 0 0 / 50%);
                
                -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                .message {
                    display: none;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 999;
                    width: 95%;
                    padding: .5rem;
                    border-radius: 2rem 0 0 2rem;
                    
                    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                            animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                    &.success {
                        background: rgb(21 191 74 / .9);
                    }      
                    
                    &.error {
                        background: rgb(249 57 57 / 0.9);
                    }

                    &.show {
                        display: block;
                    }

                    p {
                        color: #fff;
                        font-size: .9rem;
                        line-height: 1.1;

                        i.fa {
                            display: block;
                            color: #fff;
                            font-size: 1.5rem;
                            margin-bottom: 5px;
                        }
                    }
                }

                .header {
                    position: relative;
                    text-align: center;
                    color: white;
                    border-radius: 5px;
                    width: 100%;
                    background: linear-gradient(60deg, #fa9a21 0%, #eb6123 100%);

                    .logo-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    
                        height: 33vh;
                        width: 100%;
                        margin: 0;
                        padding: 0;

                        .logo {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        
                            padding: .5rem;
                            width: 150px;
                            height: 150px;
                            border-radius: 50%;
                            box-shadow: 0 0 15px 5px #f09335;
                            box-shadow: 0 0 15px 5px #edda23;
                            background: linear-gradient(60deg, #fb8c00 0%, #f54c00 100%);
                            
                            -webkit-animation: jello-vertical 0.9s both;
                                    animation: jello-vertical 0.9s both;
                            -webkit-animation-delay: 0.3s;
                                    animation-delay: 0.3s;

                            img {
                                width: 95%;
                            }
                        }
                    }

                    .waves {
                        position: relative;
                        width: 100%;
                        height: 7vh;
                        margin-bottom: -8px;
                        min-height: 65px;
                        max-height: 80px;

                        .parallax {
                            > use {
                                animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;

                                &:nth-child(1) {
                                    animation-delay: -2s;
                                    animation-duration: 7s;
                                }

                                &:nth-child(2) {
                                    animation-delay: -3s;
                                    animation-duration: 10s;
                                }

                                &:nth-child(3) {
                                    animation-delay: -4s;
                                    animation-duration: 13s;
                                }

                                &:nth-child(4) {
                                    animation-delay: -5s;
                                    animation-duration: 20s;
                                }
                            }
                        }
                    }
                }

                form {
                    width: 100%;
                    height: 100%;

                    &#frmChangePassword {
                        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    }

                    input:placeholder {
                        color: #333;
                    }

                    .body {
                        width: 100%;
                        padding: 1rem;

                        .security-question {
                            text-align: left;
                            font-size: 15px;
                            line-height: 1.1;
                            margin-bottom: .5rem;
                        }

                        .error-message {
                            display: none;
                            font-size: .7rem;
                            line-height: 1.1;
                            color: #dc3545;

                            &.show {
                                display: block;
                            }
                        }

                        input {
                            width: 100%;
                            border-radius: 100px;
                            font-family: var(--font-family);
                            outline: none;
                            font-size: .9rem;
                            background: #fff;
                            color: #333;
                            padding: 10px 15px;
                            margin: 5px 0;
                            border: 1px solid #cacaca;
                        }

                        button {
                            width: 100%;
                            margin: .7rem 0;
                            padding: 10px 15px;
                            border-radius: 100px;
                            border: none;
                            font-size: .9rem;
                            outline: none;
                            color: #fff;
                            cursor: pointer;
                            background: var(--gradient-main);
                        }

                        .form-group {
                            position: relative;

                            input {
                                padding-right: 30px;
                            }

                            .change-view {
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: auto !important;
                                margin: 0 !important;
                                padding: 0 !important;
                                background: none !important;
                                border: none !important;

                                i.fa {
                                    font-size: 1.1rem;
                                    color: #333;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}













/*
 * ANIMATIONS
 */
 @-webkit-keyframes move-forever {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% { 
        transform: translate3d(85px,0,0);
    }
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% { 
        transform: translate3d(85px,0,0);
    }
}













/*
 * Responsive Design
 */
 @media screen and (max-width: 525px) {
    .rty {
        .change-password {
            &::before {
                display: none;
            }

            .container {
                position: unset;
                left: unset;
                top: unset;
                transform: unset;
                width: 100%;
                min-height: 100%;

                .form-wrapper {
                    width: 100%;

                    .header {
                        border-radius: 0;

                        .logo-container {
                            height: 45vh;
                        }
                    }

                    form {
                        box-shadow: none;
                        border-radius: 0;
                        height: 100%;
                        width: 100%;

                        .body {
                            margin-top: 2rem;

                            input {
                                font-size: 1rem;
                                margin: .5rem 0;
                            }

                            a,
                            button {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
 }

 @media screen and (max-width: 320px) {
    .rty {
        .change-password {
            .container {
                .form-wrapper {
                    .header {
                        .waves {
                            margin-bottom: -15px;
                        }
                    }
                    
                    form {
                        body input {
                            margin: .3rem 0;
                        }
                    }
                }
            }
        }
    }
 }