.rty {
    .dashboard {
        .site {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        
            width: 100%;
            background-color: #fff;
            border-radius: 1rem;
            padding: 3rem 1rem 1rem;
            margin-bottom: 1rem;
            position: relative;
            box-shadow: 0 0 36px -15px var(--color-grey);
            -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            .site-logo {
                position: absolute;
                top: -2rem;
                left: 50%;
                transform: translateX(-50%);
            
                display: flex;
                justify-content: center;
                align-items: center;
            
                width: 80px;
                height: 65px;
                background: #e1e1e1;
                padding: 0.5rem;
                border-radius: 1rem;

                img {
                    width: 100%;
                }
            }

            .site-details {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                height: 100px;

                h4 {
                    line-height: 1.2;
                    text-align: center;
                }

                p {
                    font-size: 12px;
                    line-height: 1.1;
                }
            }

            .site-footer {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
            
                border-top: 2px solid var(--color-main);
                padding: .5rem 0 0;

                button {
                    background-color: #cbcbcb;
                    border: none;
                    padding: 5px 10px;
                    color: #4e4e4e;
                    border-radius: 1rem;
                    cursor: pointer;

                    &:hover {
                        background-color: var(--color-main);
                    }

                    i.fa {
                        margin-right: 5px;
                    }
                }
            }
        }

        &.dark {
            .site {
                background-color: #161616;
                box-shadow: 0 0 36px -15px rgb(0 0 0 / 50%);
    
                .site-logo {
                    background-color: #242424;
                }
    
                .site-footer {
                    button {
                        color: #5e6168;
                        background-color: #242424;
    
                        &:hover {
                            background-color: var(--color-main);
                        }
                    }
                }
            }
        }
    }
}













/*
 * Responsive Design
 */
 @media screen and (max-width: 1024px) {
    .rty {
        .dashboard {
            .site {
                padding-top: 2.5rem;

                .site-logo {
                    top: -1.5rem;
                    width: 70px;
                    height: 55px;
                }
            }
        }
    }
}


@media screen and (max-width: 525px) {
    .rty {
        .dashboard {
            .site {
                padding: 1.8rem .5rem .5rem;
                border-radius: 10px;

                .site-logo {
                    top: -1.3rem;
                    width: 55px;
                    height: 45px;
                    padding: .3rem;
                    border-radius: 10px;
                }

                .site-details {
                    height: 75px;

                    h4 {
                        font-size: 13px;
                        line-height: 1.1;
                    }

                    p {
                        font-size: 10px;
                    }
                }

                .site-footer {
                    button {
                        font-size: 10px;
                        padding: 5px 7px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 320px) {
    .rty {
        .dashboard {
            .site {
                padding: 1.3rem .5rem .5rem;

                .site-logo {
                    top: -1rem;
                    width: 45px;
                    height: 35px;
                }

                .site-details {
                    height: 60px;

                    h4 {
                        font-size: 12px;
                    }

                    p {
                        font-size: 9px;
                    }
                }

                .site-footer {
                    button {
                        font-size: 9px;
                        padding: 3px 7px;
                    }
                }
            }
        }
    }
}