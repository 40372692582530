@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');

:root {
    --primary-font: 'Press Start 2P';
    --clock-font-color: #4e4e4e;
    --clock-font-color-dark: #fff;
    --glow-color: rgba(255, 255, 255, 0.5);
    --bg-color: #111;
    --selector-color: #27ae60;
}

::selection {
    background-color: var(--selector-color);
}

.rty {
    .dashboard {
        .content {
            .body {
                .sidebar-right {
                    .tms {
                        .clock {
                            font-family: var(--primary-font);
                            color: var(--clock-font-color);
                            font-size: 2rem;
                            text-align: center;
                            text-shadow: 0 0 10px var(--glow-color), 0 0 20px var(--glow-color), 0 0 30px var(--glow-color);
                        }

                        .buttons {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-gap: .5rem;
                            padding: 1rem 0 0;

                            > div {
                                > button {
                                    display: block;
                                    width: 100%;
                                    color: #fff;
                                    margin-bottom: .5rem;
                                    border-radius: 5px;
                                    padding: 10px 5px;
                                    line-height: 1.1;
                                    cursor: pointer;
                                    transition: all .5s ease-in-out;

                                    i.fa {
                                        margin-left: 3px;
                                    }

                                    &:first-child {
                                        background: #28a745;
                                        border: 1px solid #28a745;
                                    }

                                    &:last-child {
                                        background: #dc3545;
                                        border: 1px solid #dc3545;
                                        margin-bottom: 0;
                                    }

                                    &:disabled {
                                        opacity: .5;
                                        cursor: not-allowed;
                                        text-decoration: line-through !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.dark {
            .body {
                .sidebar-right {
                    .tms {
                        .clock {
                            color: var(--clock-font-color-dark);
                        }
                    }
                }
            }
        }

        
        .modal {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .5);

            display: flex;
            justify-content: center;
            align-items: center;

            &.tms-break-time {
                .wrapper {
                    background: #101010 ;
                    border: 2px solid #fa9a21;
                    border-radius: 5px;
                    width: 350px;
                    height: 150px;
                    padding: 1rem;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                
                    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                    button {
                        width: 100%;
                        margin: 0 3px;
                        height: 100%;
                        background: #0d6efd;
                        color: #fff;
                        font-size: 1.5rem;
                        line-height: 1.1;
                        border: none;
                        border-radius: 5px;
                        outline: none;
                        cursor: pointer;

                        &:hover {
                            background: #0b5ed7;
                        }

                        &:disabled {
                            opacity: .5;
                            background: #0d6efd;
                            cursor: not-allowed;
                            text-decoration: line-through !important;
                        }

                        &.close-modal {
                            position: absolute;
                            top: -10px;
                            right: -10px;
                            width: 25px;
                            height: 25px;
                            font-size: 1.2rem;
                            padding: 5px;
                            border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #dc3545;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
}














/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
            transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
            transform: scale(1);
        opacity: 1;
    }
}
















/*
 * Responsive Design
 */
@media screen and (max-width: 1024px) {
    .rty {
        .dashboard {
            .content {
                .body {
                    .sidebar-right {
                        .tms {
                            .clock {
                                font-size: 1.2rem;
                            }

                            .buttons {
                                > div {
                                    button {
                                        padding: 5px;
                                        font-size: .8rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .rty {
        .dashboard {
            .content {
                .body {
                    .sidebar-right {
                        .tms {
                            .buttons {
                                padding-top: .5rem;

                                > div {
                                    button {
                                        font-size: .6rem;
                                        padding: 5px 1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 525px) {
    .rty {
        .dashboard {
            .content {
                .body {
                    .sidebar-right {
                        flex: 0 0 250px;
                        width: 250px;

                        .tms {
                            .clock {
                                font-size: 1.5rem;
                            }

                            .buttons {
                                padding-top: .5rem;

                                > div {
                                    button {
                                        font-size: .8rem;
                                        padding: 8px 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}