* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
    outline: 0;
    scroll-behavior: smooth;
}

body {
    font-family: var(--font-family);
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    margin: auto;
    position: relative;
    color: var(--color-text);
    background-color: #eaebef;   

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
        width: 7px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fa9a21;
    }

    &.swal2-toast-shown .swal2-container {
        background-color: rgba(0, 0, 0, 0) !important;
    }
}













/**
 * ----------------------------------------
 * sweetalert
 * ----------------------------------------
 */
.swal2-container {
    background: rgba(0, 0, 0, .8) !important;

    .swal2-toast {
        background: #101010 !important;
        border: 2px solid #fa9a21;

        .swal2-title {
            color: #5e6168;
        }
    }

    .swal2-timer-progress-bar {
        background: rgb(0 123 255 / 50%) !important;
    }

    .swal2-modal {
        padding-bottom: 1rem;
        background: #101010 !important;
        border: 2px solid #fa9a21 !important;

        .swal2-icon {
            margin-top: 1em !important;
        }

        .swal2-html-container {
            color: #5e6168;
            margin-top: 0 !important;
            overflow: unset;
        }

        .swal2-actions {
            margin-top: 0.5rem;

            button {
                padding: 5px 0.5rem;
                box-shadow: none;

                &.swal2-confirm {
                    background: #007bff !important;
                }

                &.swal2-cancel {
                    background: #dc3545 !important;
                }
            }
        }
    }
    
}















/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
}
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
}
@keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation jello-vertical
 * ----------------------------------------
 */
@-webkit-keyframes jello-vertical {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
                transform: scale3d(0.75, 1.25, 1);
    }
    40% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
                transform: scale3d(1.25, 0.75, 1);
    }
    50% {
        -webkit-transform: scale3d(0.85, 1.15, 1);
                transform: scale3d(0.85, 1.15, 1);
    }
    65% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
                transform: scale3d(1.05, 0.95, 1);
    }
    75% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
                transform: scale3d(0.95, 1.05, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}

@keyframes jello-vertical {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
                transform: scale3d(0.75, 1.25, 1);
    }
    40% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
                transform: scale3d(1.25, 0.75, 1);
    }
    50% {
        -webkit-transform: scale3d(0.85, 1.15, 1);
                transform: scale3d(0.85, 1.15, 1);
    }
    65% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
                transform: scale3d(1.05, 0.95, 1);
    }
    75% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
                transform: scale3d(0.95, 1.05, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}


/**
 * ----------------------------------------
 * animation for span loading
 * ----------------------------------------
 */
@-webkit-keyframes moving-gradient {
    0% {
        background-position: -250px 0;
    }
    100% {
        background-position: 250px 0;
    }
}
@keyframes moving-gradient {
    0% {
        background-position: -250px 0;
    }
    100% {
        background-position: 250px 0;
    }
}