@import url('//fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
    --font-family: 'Poppins', sans-serif;

    --gradient-main: linear-gradient(to bottom, #f27900 0%,#fa9a21 100%);

    --color-main: #fa9a21;
    --color-dark: #f27900;
    --color-text: #4e4e4e;
    --color-grey: #a9a9a9;
    --color-danger: #dc3545;
    --color-success: #28a745;
    --color-primary: #007bff;
}