.rty {
    .dashboard {
        position: relative;
        min-height: 100vh;
        padding: 2rem 2rem 1rem;

        .content {
            min-height: 89vh;
            border-radius: 1rem;
            box-shadow: 0 10px 20px -2px rgb(0 0 0 / 10%);
        
            /* ff 3.6+ */
            background:-moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(234, 235, 239, 1) 50%); 
        
            /* safari 5.1+,chrome 10+ */
            background:-webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(234, 235, 239, 1) 50%);
        
            /* opera 11.10+ */
            background:-o-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(234, 235, 239, 1) 50%);
        
            /* ie 6-9 */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EAEBEF', endColorstr='#FFFFFF', GradientType=1 );
        
            /* ie 10+ */
            background:-ms-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(234, 235, 239, 1) 50%);
        
            /* global 94%+ browsers support */
            background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(234, 235, 239, 1) 50%);

            .topbar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                padding: 1rem 2rem;
                border-radius: 1rem 1rem 0 0;

                .logo {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .title {
                        padding-left: 5px;

                        p {
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 1.1;
                            color: var(--color-main);

                            span {
                                color: #4f4f4f;
                            }
                        }
                    }

                    img {
                        height: 50px;
                    }
                }

                .details {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .name {
                        margin-right: .5rem;
                    }

                    .avatar {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    
                        width: 40px;
                        height: 40px;
                        margin-right: .5rem;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-color: #fff;
                        border-radius: 50%;
                        box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
                    }

                    .divider {
                        background-color: var(--color-main);
                        width: 1px;
                        height: 25px;
                        margin-right: 0.5rem;
                    }

                    button {
                        color: var(--color-text);
                        background: none;
                        border: none;
                        font-size: 25px;
                        cursor: pointer;

                        &:last-child {
                            margin-left: 0.5rem;
                        }

                        &:hover {
                            color: var(--color-main);
                        }
                    }
                }
            }

            .body {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            
                padding: 0 2rem;

                .sidebar-left {
                    flex: 0 0 25px;
                    width: 25px;

                    .details {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        transform: rotate(270deg);

                        p {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            margin: 0 0.5rem;

                            i.fa {
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .container {
                    flex: 1;
                    width: 100%;
                    min-height: 75vh;
                    overflow: hidden;
                }

                .sidebar-right {
                    flex: 0 0 250px;
                    width: 250px;
                    padding-left: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .helpdesk {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 2rem;
                        background: radial-gradient(circle at 50% 50%, rgba(250, 154, 33, 1) 0%, rgba(0, 0, 0, 0) 65%);

                        img {
                            width: 100%;
                        }

                        a {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        button {
                            width: 100%;
                            background: var(--gradient-main);
                            border: none;
                            color: #fff;
                            font-size: 1rem;
                            font-weight: 700;
                            padding: .5rem 1rem;
                            border-radius: 1rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1rem;

            p {
                font-size: 12px;
            }
        }

        &.dark {
            color: #5e6168;
            background-color: #101010;

            .content {
                box-shadow: 0 0 36px -15px rgb(0 0 0 / 50%);
                background: linear-gradient(180deg, #161616 0%, #101010 50%);
                
                .topbar {
                    .details {
                        .avatar {
                            background-color: #242424;
                        }
                    }
                }

                .body {
                    .sidebar-right {
                        .helpdesk {
                            button {
                                color: #101010;
                            }
                        }
                    }
                }
            }
        }
    }
}













/*
 * Responsive Design
 */
@media screen and (max-width: 1024px) {
    .rty {
        .dashboard {
            .content {
                min-height: 88vh;

                .body {
                    .sidebar-right {
                        flex: 0 0 150px;
                        width: 150px;

                        .helpdesk {
                            button {
                                font-size: .9rem;
                                font-weight: 400;
                                padding: 5px;
                                margin-top: .5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .rty {
        .dashboard {
            padding: 1rem;

            .content {
                .topbar {
                    padding: 1rem;

                    .logo {
                        img {
                            height: 40px;
                        }

                        .title {
                            p {
                                font-size: 17px;
                            }
                        }
                    }

                    .details {
                        .name {
                            font-size: 15px;
                        }

                        .avatar {
                            width: 30px;
                            height: 30px;
                        }

                        .divider {
                            margin-right: 0;
                        }

                        button {
                            font-size: 20px;
                        }
                    }
                }
            }

            .body {
                .sidebar-left {
                    .details {
                        p {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 525px) {
    .rty {
        .dashboard {
            .content {
                .topbar {
                    .logo {
                        img {
                            height: 30px;
                        }

                        .title {
                            p {
                                font-size: 13px;
                            }
                        }
                    }

                    .details {
                        .name {
                            display: none;
                        }

                        .avatar {
                            width: 25px;
                            height: 25px;
                        }

                        button {
                            font-size: 18px;
                        }
                    }
                }

                .body {
                    flex-direction: column-reverse;
                    padding: 0 1rem 1rem;

                    .sidebar-left {
                        display: none;
                    }

                    .sidebar-right {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 375px) {
    .rty {
        .dashboard {
            padding: 0 0 1rem;

            .content {
                border-radius: 0;
                box-shadow: none;

                .topbar {
                    padding: 1rem .5rem;

                    .logo {
                        img {
                            height: 25px;
                        }

                        .title {
                            p {
                                font-size: 11px;
                            }
                        }
                    }

                    .details {
                        .avatar {
                            width: 20px;
                            height: 20px;
                        }

                        .divider {
                            height: 18px;
                        }

                        button {
                            font-size: 15px;
                        }
                    }
                }

                .body {
                    padding-bottom: 0;

                    .container {
                        min-height: auto;
                    }
                }
            }
        }
    }
}