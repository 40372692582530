.rty {
    .error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        width: 100%;
        height: 100vh;

        .message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
    
            i.fa {
                font-size: 3rem;
                color: #f93939;
            }
        }

        .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        
            position: absolute;
            bottom: 1rem;

            img {
                height: 30px;
            }

            .title {
                padding-left: 5px;

                p {
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 1.1;
                    color: var(--color-main);

                    span {
                        color: #4f4f4f;
                    }
                }
            }
        }
    }
}