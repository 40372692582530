.rty {
    .login {
        display: flex;
        font-family: var(--font-family);
        height: 100vh;
        position: relative;  
        
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            opacity: .3;
            background-image: url(../../assets/abstract.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;

            form {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                width: 310px;
                height: 100%;
                text-align: center;
                border-radius: 5px;
                background: #fff;
                overflow: hidden;
                box-shadow: 0 6px 31px -2px rgb(0 0 0 / 50%);
                
                -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                .error-message {
                    display: none;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 999;
                    width: 95%;
                    padding: .5rem;
                    border-radius: 2rem 0 0 2rem;
                    background: rgb(249 57 57 / 0.9);
                    
                    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                            animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                    &.show {
                        display: block;
                    }

                    &.hide {
                        display: none;
                    }

                    p {
                        color: #fff;
                        font-size: .9rem;
                        line-height: 1.1;

                        i.fa {
                            display: block;
                            color: #fff;
                            font-size: 1.5rem;
                        }
                    }
                }

                .header {
                    position: relative;
                    text-align: center;
                    color: white;
                    border-radius: 5px;
                    width: 100%;
                    background: linear-gradient(60deg, #fa9a21 0%, #eb6123 100%);

                    .logo-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        height: 33vh;
                        width: 100%;
                        margin: 0;
                        padding: 0;

                        .logo {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            padding: .5rem;
                            width: 150px;
                            height: 150px;
                            border-radius: 50%;
                            box-shadow: 0 0 15px 5px #f09335;
                            box-shadow: 0 0 15px 5px #edda23;
                            background: linear-gradient(60deg, #fb8c00 0%, #f54c00 100%);
                            
                            -webkit-animation: jello-vertical 0.9s both;
                                    animation: jello-vertical 0.9s both;
                            -webkit-animation-delay: 0.3s;
                                    animation-delay: 0.3s;

                            img {
                                width: 95%;
                            }
                        }
                    }

                    .waves {
                        position: relative;
                        width: 100%;
                        height: 7vh;
                        margin-bottom: -8px;
                        min-height: 65px;
                        max-height: 80px;   
        
                        .parallax {
                            > use {
                                animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        
                                &:nth-child(1) {
                                    animation-delay: -2s;
                                    animation-duration: 7s;
                                }
        
                                &:nth-child(2) {
                                    animation-delay: -3s;
                                    animation-duration: 10s;
                                }
        
                                &:nth-child(3) {
                                    animation-delay: -4s;
                                    animation-duration: 13s;
                                }
        
                                &:nth-child(4) {
                                    animation-delay: -5s;
                                    animation-duration: 20s;
                                }
                            }
                        }
                    }
                }

                .body {
                    padding: 1rem;

                    input {
                        width: 100%;
                        border-radius: 100px;
                        font-family: var(--font-family);
                        outline: none;
                        font-size: .9rem;
                        background: #fff;
                        color: #333;
                        padding: 10px 15px;
                        margin: 5px 0;
                        border: 1px solid #cacaca;
                    }

                    button {
                        width: 100%;
                        margin: .7rem 0;
                        padding: 10px 15px;
                        border-radius: 100px;
                        border: none;
                        font-size: .9rem;
                        outline: none;
                        color: #fff;
                        cursor: pointer;
                        background: var(--gradient-main);
                    }

                    p a {
                        font-family: var(--font-family);
                        font-size: 13px;
                        color: #333;
                        line-height: 1.2;
                        margin-top: .7rem;
                        background: none;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            color: var(--color-main);
                        }
                    }
                }
            }
        }
    }
}











/*
 * ANIMATIONS
 */
 @-webkit-keyframes move-forever {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% { 
        transform: translate3d(85px,0,0);
    }
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% { 
        transform: translate3d(85px,0,0);
    }
}


/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
}
  
  
  
  








/*
 * Responsive Design
 */
@media screen and (max-width: 525px) {
    .rty {
        .login {
            &::before {
                display: none;
            }

            .container {
                position: unset;
                left: unset;
                top: unset;
                transform: unset;
                width: 100%;
                min-height: 100%;

                form {
                    box-shadow: none;
                    border-radius: 0;
                    height: 100%;
                    width: 100%;

                    .header {
                        border-radius: 0;

                        .logo-container {
                            height: 45vh;
                        }
                    }

                    .body {
                        margin-top: 2rem;

                        input {
                            font-size: 1rem;
                            margin: .5rem 0;
                        }

                        a,
                        button {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .rty {
        .login {
            .container {
                .header {
                    .waves {
                        margin-bottom: 15px;
                    }
                }

                .body {
                    input {
                        margin: .3rem 0;
                    }
                }
            }
        }
    }
}