.rty {
    .verify-account {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2rem 0;
        min-height: 100vh;

        .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;

            img {
                width: 100px;
            }

            title {
                padding-left: 5px;

                p {
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 1;
                    color: var(--color-main);

                    span {
                        color: #4f4f4f;
                    }
                }
            }
        }

        .verify-temporary-password {
            form {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                input {
                    width: 300px;
                    font-size: .9rem;
                    background: none;
                    border: none;
                    outline: none;
                    color: #333;
                    padding: 5px;
                    margin: 5px 0;
                    font-family: var(--font-family);
                    border-bottom: 1px solid #4f4f4f;
                }

                .error-message {
                    display: none;
                    font-size: .8rem;
                    font-weight: 700;
                    line-height: 1.1;
                    color: #dc3545;
                    
                    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                            animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

                    &.show {
                        display: block;
                    }
                }

                button {
                    width: 40%;
                    margin-top: 1rem;
                    padding: 8px 10px;
                    border-radius: 5px;
                    border: none;
                    font-size: .9rem;
                    outline: none;
                    color: #fff;
                    cursor: pointer;
                    background: var(--gradient-main);
                }
            }
        }

        .account-update {
            -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            .error-message {
                display: none;
                font-size: .8rem;
                line-height: 1.1;
                color: #fff;
                width: 95%;
                padding: 8px;
                margin: 0 auto 1rem;
                border-radius: 5px;
                background: #dc3545;
                
                -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                &.show {
                    display: block;
                }

                i.fa {
                    font-size: 1rem;
                    margin-right: 5px;
                }
            }

            .details {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                .container {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                
                    width: 300px;
                    padding: 0 1rem;
                    overflow: hidden;

                    .avatar {
                        width: 100%;
                        padding: .5rem .5rem 1rem;
                        position: relative;
                        border-radius: 1rem;
                        border: 3px solid var(--color-main);

                        img {
                            width: 100%;
                            border-radius: 1rem;
                        }

                        label {
                            position: absolute;
                            bottom: -1.3rem;
                            right: 0.5rem;
                            cursor: pointer;

                            input {
                                visibility: hidden;
                                width: 0;
                            }

                            i.fa {
                                background: #eaebef;
                                border: 3px solid var(--color-main);
                                color: var(--color-main);
                                font-size: 1.3rem;
                                padding: 5px 6px;
                                border-radius: 100%;
                            }

                            &:hover {
                                i.fa {
                                    background: var(--color-main);
                                    color: #fff;
                                }
                            }
                        }
                    }

                    .content {
                        width: 100%;
                        margin-top: 1rem;

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            margin-bottom: .5rem;
                            border-bottom: 1px solid #b5b5b5;

                            label {
                                font-size: 12px;
                            }

                            h4 {
                                width: 100%;
                                font-size: .9rem;
                                line-height: 1.1;
                                padding-left: 5px;
                                cursor: not-allowed;
                            }
                        }
                    }
                }

                .input-wrapper {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                
                    width: 300px;
                    padding: 0 1rem;
                    overflow: hidden;

                    > div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                    
                        margin-bottom: .5rem;
                        border-bottom: 1px solid #b5b5b5;
                    }

                    label {
                        font-size: 12px;
                    }

                    input,
                    select,
                    textarea {
                        width: 100%;
                        background: none;
                        border: none;
                        font-size: 1rem;
                        padding: 5px;
                        color: var(--color-text);
                    }

                    .input-with-button {
                        width: 100%;
                        position: relative;

                        input {
                            padding-right: 30px;
                        }

                        button {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                            width: auto;
                            margin: 0;
                            padding: 0;
                            background: none;
                            border: none;
                            cursor: pointer;

                            i.fa {
                                font-size: 1rem;
                                color: var(--color-text);
                            }
                        }
                    }

                    .error-message {
                        display: none;
                        font-size: .7rem;
                        line-height: 1.1;
                        color: var(--color-danger);

                        &.show {
                            display: block;
                        }
                    }
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 2rem 0 1rem;

                button {
                    width: 200px;
                    font-size: 1rem;
                    padding: .5rem 1rem;
                    color: #fff;
                    border: none;
                    border-radius: .5rem;
                    background: var(--gradient-main);
                    cursor: pointer;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            
            position: absolute;
            bottom: 0.5rem;
            left: 50%;
            transform: translateX(-50%);

            p {
                font-size: 12px;
            }
        }
    }
}

input:disabled,
select:disabled,
textarea:disabled,
button:disabled {
    cursor: not-allowed;
}












/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
}
@keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
}





  
  

  










/*
 * Responsive Design
 */
 @media screen and (max-width: 525px) {
    .rty {
        .verify-account {
            .account-update {
                .details {
                    flex-direction: column;

                    .container {
                        width: 100%;
                        padding: 0 2rem;
                        margin-bottom: 2rem;
                    }

                    .input-wrapper {
                        width: 100%;
                        padding: 0 2rem;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .rty {
        .verify-account {
            .logo {
                .title {
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .rty {
        .verify-account {
            .logo {
                img {
                    width: 70px;
                }
                .title {
                    p {
                        font-size: 18px;
                    }
                }
            }
            
            .account-update {
                .details {
                    flex-direction: column;

                    .container {
                        padding: 0 1rem;
                    }

                    .input-wrapper {
                        padding: 0 1rem;
                    }
                }
            }
        }
    }
}