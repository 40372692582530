.rty {
    .loader {
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        right: 0;
        height: 200px;
        width: 200px;

        .dot {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 52.5%;
            left: 100px;
            height: 15px;
            width: 15px;
            background-color: #fa9a21;
            border-radius: 5rem;
            transition: all 0.15s ease-in-out;

            &:nth-child(1) {
                left: 30px;
                animation: waveUp 1.5s, smallExtend 1.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
            }

            &:nth-child(2) {
                left: 65px;
                animation: waveUp 1.5s, largeExtend 1.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-delay: 0.15s;
            }

            &:nth-child(3) {
                animation: waveUp 1.5s, smallExtend 1.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-delay: 0.3s;
            }

            &:nth-child(4) {
                left: 135px;
                animation: waveUp 1.5s, largeExtend 1.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-delay: 0.45s;
            }

            &:nth-child(5) {
                left: 170px;
                animation: waveUp 1.5s, smallExtend 1.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-delay: 0.6s;
            }
        }
    }   
}











/*
 * ANIMATIONS
 */
@keyframes waveUp {
    0%,
    15% {
        top: 50%;
    }
    45%,
    65% {
        top: 42.5%;
    }
    85%,
    100% {
        top: 50%;
    }
}

@keyframes smallExtend {
    0%,
    8% {
        background-color: #fa9a21;
        height: 15px;
    }
    14%,
    34% {
        background-color: #f27900;
        height: 37.5px;
    }
    46%,
    100% {
        background-color: #fa9a21;
        height: 15px;
    }
}

@keyframes largeExtend {
    0%,
    8% {
        background-color: #fa9a21;
        height: 15px;
    }
    14%,
    34% {
        background-color: #f27900;
        height: 72.5px;
    }
    46%,
    100% {
        background-color: #fa9a21;
        height: 15px;
    }
}
  