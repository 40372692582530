.rty {
    .dashboard {
        .sites {
            display: flex;
            flex-direction: column;
            justify-content: center;
        
            padding: 1rem;

            .sites-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid #dedfe2;
            }

            .sites-content {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 2rem;
                justify-content: flex-start;
                align-items: start;
                
                padding: 2rem;
                height: 67vh;
                border-radius: 0 0 1rem 1rem;
                overflow-y: auto;
                overflow-x: hidden;
                scroll-behavior: smooth;
                position: relative;

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar {
                    width: 7px;
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #fa9a21;
                }
            }
        }

        &.dark {
            .sites {
                .sites-header {
                    border-color: #5e6168;

                    h2 {
                        color: #5e6168;
                    }
                }
            }
        }
    }
}














/*
 * Responsive Design
 */
 @media screen and (max-width: 1024px) {
    .rty {
        .dashboard {
            .sites {
                .sites-header {
                    h2 {
                        font-size: 1.2rem;
                    }

                    input {
                        width: 200px;
                    }
                }

                .sites-content {
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 1.5rem;
                    padding-top: 2.5rem;
                    height: 60vh;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .rty {
        .dashboard {
            .sites {
                .sites-header {
                    h2 {
                        font-size: 1rem;
                    }
                }

                .sites-content {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 1.5rem;
                    padding: 2rem 1rem 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 525px) {
    .rty {
        .dashboard {
            .sites {
                padding: 1rem 0;

                .sites-header {
                    input {
                        width: 150px;
                    }
                }

                .sites-content {
                    grid-gap: 1rem;
                    height: auto;
                    padding: 2rem 0 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .rty {
        .dashboard {
            .sites {
                .sites-header {
                    margin-bottom: .5rem;
                    padding-bottom: .5rem;

                    h2 {
                        font-size: .9rem;
                    }

                    input {
                        font-size: 12px;
                        height: 25px;
                    }
                }

                .sites-content {
                    height: auto;
                    min-height: 47vh;
                    grid-gap: .5rem;
                    padding: 1.5rem .5rem .5rem;
                }
            }
        }
    }
}