.rty {
    .dashboard {
        .itpd-helpdesk {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        
            padding: 1rem;

            .page-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid var(--color-grey);

                button {
                    background: none;
                    border: none;
                    font-size: 25px;
                    color: var(--color-grey);
                    cursor: pointer;

                    &:hover {
                        color: var(--color-main);
                    }
                }
            }

            .page-body {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
            
                width: 100%;
                min-height: 65vh;
                overflow-y: auto;

                .table-holder {                    
                    width: 100%;
                    min-height: 50vh;
                    position: relative;

                    .rdt_Table {
                        background: none;

                        .rdt_TableHeadRow {
                            background: none;

                            .rdt_TableCol {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: var(--gradient-main);
                                color: #fff;
                                border-right: 1px solid var(--color-grey);
                                padding: 3px 5px;
                                line-height: 1.1;
                                text-align: center;
                            }
                        }

                        .rdt_TableBody {
                            .rdt_TableRow {
                                background: none;

                                .rdt_TableCell {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    min-height: 20px;
                                    font-size: 12px;
                                    line-height: 1.1;
                                    padding: 3px 5px;
                                    border-left: 1px solid var(--color-grey);
                                    border-bottom: 1px solid var(--color-grey);

                                    &:last-child {
                                        border-right: 1px solid var(--color-grey);
                                    }

                                    .badge {
                                        display: inline-block;
                                        padding: 0.25em 0.4em;
                                        font-size: 75%;
                                        font-weight: 700;
                                        line-height: 1;
                                        color: #fff;
                                        text-align: center;
                                        white-space: nowrap;
                                        vertical-align: baseline;
                                        border-radius: 0.25rem;

                                        &.badge-success {
                                            background-color: #28a745;
                                        }

                                        &.badge-danger {
                                            background-color: #dc3545;
                                        }

                                        &.badge-primary {
                                            background-color: #007bff;
                                        }

                                        &.badge-secondary {
                                            background-color: #6c757d;
                                        }

                                        &.badge-info {
                                            background-color: #17a2b8;
                                        }

                                        &.badge-warning {
                                            background-color: #ffc107;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .rdt_Pagination {
                        background: none;
                    }
                }

                form {
                    flex: 0 0 300px;
                    width: 300px;
                    padding: 0 1rem;
                    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                    .form-message {
                        display: none;
                        color: #fff;
                        font-size: .9rem;
                        line-height: 1.2;
                        padding: 10px;
                        border-radius: 5px;
                        margin-bottom: .5rem;
                    
                        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                        &.success-message {
                            background: var(--color-success);
                        }

                        &.error-message {
                            background: var(--color-danger);
                        }

                        &.show {
                            display: block;
                        }

                        i.fa {
                            font-size: 1.2rem;
                            margin-right: 5px;
                        }
                    }

                    .note {
                        color: #f00;
                        font-size: 12px;
                        line-height: 1.1;
                        margin-top: 5px;
                        transition: all ease-in-out .5s;

                        i.fa {
                            margin-right: 5px;
                        }
                    }
                    
                    div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: .5rem;
                    }

                    input,
                    select,
                    textarea {
                        display: block;
                        width: 100%;
                        outline: none;
                        padding: 3px 5px;
                        border-radius: 5px;
                        border: 1px solid var(--color-grey);
                        font-family: var(--font-family);

                        &:disabled {
                            opacity: .9; 
                            cursor: not-allowed;
                        }
                    }

                    input[type=file] {
                        border: none;
                        padding: 0;
                        border-radius: 0;
                    }

                    button {
                        background: var(--gradient-main);
                        color: #fff;
                        border: none;
                        padding: .5rem;
                        border-radius: 1rem;
                        margin-top: 1rem;
                        cursor: pointer;

                        &:disabled {
                            opacity: .9; 
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }

        .page-error {
            display: flex;
            justify-content: center;
            align-items: center;
        
            width: 100%;
            height: 100%;
            min-height: 75vh;

            p {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i.fa {
                    font-size: 3rem;
                    color: var(--color-danger);
                    margin-bottom: .5rem;
                }
            }
        }

        &.dark {
            .itpd-helpdesk {
                .page-body {
                    .table-holder {
                        .rdt_Table {
                            .rdt_TableHeadRow {
                                .rdt_TableCol {
                                    color: #101010;
                                }
                            }

                            .rdt_TableBody {
                                .rdt_TableRow {
                                    color: var(--color-grey);
                                }
                            }
                        }
                    }

                    form {
                        .form_message {
                            color: #101010;
                        }

                        input,
                        select,
                        textarea {
                            background-color: #161616;
                            border-color: #5e6168;
                            color: #5e6168;
                        }

                        button {
                            color: #101010;
                        }
                    }
                }
            }
        }
    }
}