.rty {
    .gJnpNS {
        background: none;
    }
    
    .table-loader {
        width:  100%;

        thead {
            tr {
                th {
                    background: var(--gradient-main);
                    color: #fff;
                    border-right: 1px solid var(--color-grey);
                    padding: 3px 5px;
                    line-height: 1.1;
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                td {
                    min-height: 20px;
                    font-size: 12px;
                    line-height: 1.1;
                    padding: 3px 5px;
                    border-left: 1px solid var(--color-grey);
                    border-bottom: 1px solid var(--color-grey);
                }
            }
        }

        &.loading {
            tbody {
                tr {
                    td {
                        span {
                            display: block;
                            height: 18px;
                            min-width: 30px;
                            font-size: 0.8rem;
                            color: transparent;
                            border-radius: 3px;
                            background: linear-gradient(to right, #f1f1f1 20%, #c1c1c1 50%, #f1f1f1 80%);
                            background-size: 500px 100px;
                            -webkit-animation-name: moving-gradient;
                            animation-name: moving-gradient;
                            -webkit-animation-duration: 1s;
                            animation-duration: 1s;
                            -webkit-animation-iteration-count: infinite;
                            animation-iteration-count: infinite;
                            -webkit-animation-timing-function: linear;
                            animation-timing-function: linear;
                            -webkit-animation-fill-mode: forwards;
                            animation-fill-mode: forwards;
                        }
                    }
                }
            }
        }
    }
}