.rty {
    .dashboard {
        .settings {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        
            padding: 1rem;
            min-height: 75vh;
            overflow-y: auto;

            .page-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid var(--color-grey);

                button {
                    background: none;
                    border: none;
                    font-size: 25px;
                    color: var(--color-grey);
                    cursor: pointer;

                    &:hover {
                        color: var(--color-main);
                    }
                }
            }

            .page-body {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
            
                width: 100%;
                min-height: 50vh;
                max-height: 65vh;
                padding: 0 1rem;
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar {
                    width: 7px;
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #fa9a21;
                }

                .sidebar {
                    flex: 0 0 200px;
                    width: 200px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    button {
                        width: 100%;
                        padding: 10px;
                        border: none;
                        color: #4e4e4e;
                        font-size: 1rem;
                        font-weight: 700;
                        cursor: pointer;
                        background: #eaebef;

                        &.active,
                        &:hover {
                            color: #4e4e4e;
                            background: #e1e1e1;
                        }   
                        
                        &:first-child {
                            border-radius: 5px 0 0 0;
                            border-bottom: 1px solid #cacaca;
                        }

                        &:last-child {
                            border-radius: 0 0 0 5px;
                        }
                    }
                }

                .page-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    background: #e1e1e1;
                    border-radius: 0 5px 5px 5px;

                    .panel {
                        width: 49%;
                        border-radius: 5px;
                        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                        .panel-header {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                        
                            width: 100%;
                            color: var(--color-text);
                            padding: 5px 0;
                            border-bottom: 3px double var(--color-text);

                            h3 {
                                font-size: 1rem;

                                i.fa {
                                    margin-right: 7px;
                                }
                            }

                            button {
                                background-color: var(--color-dark);
                                color: #fff;
                                border: none;
                                border-radius: 5px;
                                padding: 3px .5rem;
                                cursor: pointer;

                                &:hover {
                                    background-color: var(--color-main);
                                }
                            }
                        }

                        .panel-content {
                            padding: 1rem;
                            border-radius: 0 0 5px 5px;

                            h3 {
                                font-size: 1rem;
                                line-height: 1.2;
                            }

                            p {
                                font-size: .9rem;
                                line-height: 1.2;
                                margin-bottom: 1rem;
                            }
                        }
                    }

                    .security {
                        display: none;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;

                        &.show {
                            display: flex;
                        }

                        .form-security-question {
                            height: 0;
                            padding: 1rem 0;
                            -webkit-animation: scale-out-center 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
                                    animation: scale-out-center 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
                                    
                            &.show {
                                height: 100%;
                                display: block;
                                -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                            }

                            .form-message {
                                display: none;
                                color: #fff;
                                font-size: .9rem;
                                line-height: 1.2;
                                padding: 10px;
                                border-radius: 5px;
                                margin-bottom: .5rem;
                            
                                -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                                &.success-message {
                                    background: var(--color-success);
                                }

                                &.error-message {
                                    background: var(--color-danger);
                                }

                                &.show {
                                    display: block;
                                }

                                i.fa {
                                    font-size: 1.2rem;
                                    margin-right: 5px;
                                }
                            }

                            > div {
                                margin-bottom: 1rem;

                                label {
                                    font-size: .9rem;
                                }

                                input,
                                select {
                                    width: 100%;
                                    background: none;
                                    border: 1px solid var(--color-text);
                                    border-radius: 5px;
                                    font-size: .9rem;
                                    padding: 5px;
                                    color: var(--color-text);
                                }

                                input:disabled,
                                select:disabled,
                                button:disabled {
                                    opacity: .8;
                                    cursor: not-allowed !important;
                                }

                                .input-with-button {
                                    width: 100%;
                                    position: relative;

                                    input {
                                        padding-right: 30px;
                                    }

                                    button {
                                        position: absolute;
                                        right: 5px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        width: auto;
                                        margin: 0;
                                        padding: 0;
                                        background: none;
                                        border: none;
                                        cursor: pointer;

                                        i.fa {
                                            font-size: 1rem;
                                            color: var(--color-text);
                                        }
                                    }
                                }
                            }

                            .button-group {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;

                                button {
                                    background-color: var(--color-dark);
                                    color: #fff;
                                    border: none;
                                    border-radius: 5px;
                                    padding: 5px .5rem;
                                    margin-left: 5px;
                                    font-size: .9rem;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: var(--color-main);
                                    }

                                    &:last-child {
                                        opacity: .9;
                                    }
                                }
                            }
                        }
                    }

                    .appearance {
                        display: none;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;

                        &.show {
                            display: flex;
                        }

                        .panel {
                            width: 100%;

                            .panel-content {
                                .theme-error {
                                    display: none;
                                    background: var(--color-danger);
                                    color: #fff;
                                    font-size: .9rem;
                                    line-height: 1.2;
                                    padding: 10px;
                                    border-radius: 5px;
                                    margin-bottom: .5rem;
                                
                                    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                                    &.show {
                                        display: block;

                                        i.fa {
                                            font-size: 1.2rem;
                                            margin-right: 5px;
                                        }
                                    }
                                }

                                form {
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr);
                                    grid-gap: 1rem;

                                    label {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        align-items: center;
                                        
                                        width: 100%;
                                        position: relative;

                                        img {
                                            width: 100%;
                                            border-radius: 10px;
                                        }

                                        input {
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            width: 100%;
                                            height: 100%;
                                            background: none;
                                            border-radius: 10px;
                                            appearance: none;
                                            cursor: pointer;

                                            &.active {
                                                border: 3px solid #007bff;
                                            }

                                            &:disabled {
                                                cursor: not-allowed;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.dark {
            .settings {
                .page-body {
                    .sidebar {
                        button {
                            background: #151515;

                            &.active,
                            &:hover {
                                background: #1a1a1a;
                                border-color: #131313;
                                color: var(--color-main);
                            }

                            &:first-child {
                                border-bottom: none;
                            }
                        }
                    }

                    .page-content {
                        background: #1a1a1a;

                        .panel {
                            .panel-header {
                                button {
                                    color: #101010;
                                }
                            }
    
                            .panel-content {
                                button {
                                    color: #101010;
                                }
                            }
                        }

                        .security {
                            .form-security-question {
                                .form-message {
                                    color: #101010;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

















/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
}